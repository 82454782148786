import { LocalizedString } from '../../utils/localizer';

export const projectAccounting: LocalizedString = {
  inflowNotRespondingErrorLabel: {
    en: 'An error has occurred with the retrieval of the project accounts. Try again later.',
    nb: 'Det har oppstått en feil med uthenting av prosjektregnskapet. Prøv igjen senere.',
    nn: 'Det har oppstått ein feil med uthentinga av prosjektregnskapet. Prøv igjen seinare.'
  },
  sanityNotRespondingWarningLabel: {
    en: 'An error has occurred with the descriptions of the costs. Try again later, or continue without descriptions.',
    nb: 'Det har oppstått en feil med beskrivelser av kostnadene. Prøv igjen senere, eller fortsett uten beskrivelser.',
    nn: 'Det har oppstått ein feil med skildringane av kostnadane. Prøv igjen seinare, eller hald fram utan skildringar.'
  },
  ProjectAccountingSum: {
    en: 'Sum',
    nb: 'Sum',
    nn: 'Sum'
  },
  ProjectAccountingSumApproved: {
    en: 'Approved',
    nb: 'Godkjent',
    nn: 'Godkjend'
  },
  ProjectAccountingSumAppliedFor: {
    en: 'Applied for',
    nb: 'Omsøkt',
    nn: 'Søkt om'
  },
  costType: {
    en: 'Type',
    nb: 'Type',
    nn: 'Type'
  },
  activityName: {
    en: 'Activity name:',
    nb: 'Aktivitetsnavn:',
    nn: 'Aktivitetsnamn:'
  },
  approved: {
    en: 'Approved',
    nb: 'Godkjent',
    nn: 'Godkjend'
  },
  appliedFor: {
    en: 'Applied for',
    nb: 'Omsøkt',
    nn: 'Omsøkt'
  },
  actualCosts: {
    en: 'Actual costs',
    nb: 'Faktiske kostnader',
    nn: 'Faktiske kostnader'
  },
  total: {
    en: 'Total:',
    nb: 'Total:',
    nn: 'Total:'
  },
  projectAccountingSubmitted: {
    en: 'Project accounting is submitted',
    nb: 'Prosjektregnskapet er sendt inn',
    nn: 'Prosjektrekneskapet er sendt inn'
  },
}