import { Dictionary } from './typescript.utils';

export const apiDispatcher = <T extends {}>(url: string, method: 'GET' | 'POST' | 'PUT' | 'DELETE', data?: object | string, config?: DispatchConfig): Promise<T> => {
  const contentType = config?.contentType ?? 'application/json';

  return new Promise((resolve, reject) => {
    fetch(url, {
      method: method,
      body: method === 'GET' ? null : data instanceof File ? data : JSON.stringify(data),
      credentials: 'same-origin',
      headers: {
        'Content-Type': contentType,
      },
    })
      .then((response) => {
        if (response.status === 401) {
          window.location.pathname = '/login';
          return;
        }

        if (response.status === 204) {
          resolve({} as T);
          return;
        }

        if (!response.ok) reject(response);

        resolve(config?.resolver !== undefined ? config.resolver(response) : response.json());
      })
      .catch((error) => {
        // TODO proper error handling at some point
        reject(error);
      });
  });
};

export interface DispatchConfig {
  contentType?: string;
  resolver?: (response: Response) => Promise<any>;
}

let cache: Dictionary = {};
let ongoingRequests = new Map<string, Promise<unknown>>();

/**
 * Standardized API dispatcher with memoization and blocking existing requests. Useful for parallel requests that should not be made multiple times.
 * @param shouldMemoize If true, the result of the request will be cached and returned if the same request is made again
 */
export const apiDispatcherWithMemoizationAndBlockingRequests = <T extends {}>(
  url: string,
  method: 'GET' | 'POST' | 'PUT' | 'DELETE',
  data?: object | string,
  config?: DispatchConfig,
  shouldMemoize?: boolean
): Promise<T> => {
  const cacheKey = JSON.stringify({ url, method, data, config });

  if (shouldMemoize && cache[cacheKey] !== undefined) {
    return Promise.resolve(cache[cacheKey] as T);
  }

  if (ongoingRequests.has(cacheKey)) {
    return ongoingRequests.get(cacheKey) as Promise<T>;
  }

  const contentType = config?.contentType ?? 'application/json';

  const request = new Promise((resolve, reject) => {
    fetch(url, {
      method: method,
      body: method === 'GET' ? null : data instanceof File ? data : JSON.stringify(data),
      credentials: 'same-origin',
      headers: {
        'Content-Type': contentType,
      },
    })
      .then((response) => {
        if (response.status === 401) {
          window.location.pathname = '/login';
          return;
        }

        if (response.status === 204) {
          resolve({} as T);
          return;
        }

        if (!response.ok) reject(response);

        const result = config?.resolver !== undefined ? config.resolver(response) : response.json();
        if (shouldMemoize) {
          cache[cacheKey] = result;
        }
        resolve(result);
      })
      .catch((error) => {
        console.warn(`Error in ${apiDispatcherWithMemoizationAndBlockingRequests.name}`, error);
        reject(error);
      })
      .finally(() => {
        ongoingRequests.delete(cacheKey);
      });
  });

  ongoingRequests.set(cacheKey, request);
  return request as Promise<T>;
};
