import { useNavigate } from 'react-router';
import { CustomerTaskDto, CustomerTaskTypeEnum } from '../models';
import { getApplicationTaskFormAction } from '../services/formActions.service';
import { useFormActionHandler } from './useFormActionHandler';

export const useCustomerTaskClickHandler = () => {
  const navigate = useNavigate();
  const formActionHandler = useFormActionHandler();

  const applicationTaskHandler = (task: CustomerTaskDto) => {
    navigate(`/applications/${task.id}`);
  };

  const formTaskHandler = (task: CustomerTaskDto) => {
    const submittedOrEdit = task.active ? 'edit' : 'submitted';

    navigate(`/forms/${submittedOrEdit}/${task.id}`);
  };

  const draftTaskHandler = async (task: CustomerTaskDto) => {
    navigate(`/forms/edit/${task.id}`);
  };

  const inflowTaskHandler = async (task: CustomerTaskDto) => {
    const applicationId = task.data?.applicationId;
    const formAction = await getApplicationTaskFormAction(applicationId, task.id as string);

    await formActionHandler(formAction);
  };

  return async (task: CustomerTaskDto) => {
    switch (task.type) {
      case CustomerTaskTypeEnum.Application:
        applicationTaskHandler(task);
        break;
      case CustomerTaskTypeEnum.Forms:
        formTaskHandler(task);
        break;
      case CustomerTaskTypeEnum.Inflow:
        await inflowTaskHandler(task);
        break;
      case CustomerTaskTypeEnum.ConferenceGrant:
        await draftTaskHandler(task);
        break;
      default:
        console.error(`No task click handler for task type ${task.type}`);
    }
  };
};
