import { LocalizedString } from '../../utils/localizer';

export const invoices: LocalizedString = {

  header: {
    en: 'Invoice overview',
    nb: 'Fakturaoversikt',
    nn: 'Fakturaoversikt',
  },
  dueDate: {
    en: 'Due date',
    nb: 'Betalingsfrist',
    nn: 'Betalingsfrist',
  },
  totalAmount: {
    en: 'Amount due',
    nb: 'Beløp å betale',
    nn: 'Beløp å betale',
  },
  originalAmount: {
    en: 'Original amount',
    nb: 'Opprinnelig beløp',
    nn: 'Opphavleg beløp',
  },
  paymentAccountNumber: {
    en: 'Payable to account',
    nb: 'Betales til konto',
    nn: 'Betalast til konto',
  },
  customerIdentification: {
    en: 'KID-number',
    nb: 'KID-nummer',
    nn: 'KID-nummer',
  },
  invoiceNumber: {
    en: 'Invoice',
    nb: 'Faktura',
    nn: 'Faktura',
  },
  originalDueDate: {
    en: 'Due date',
    nb: 'Forfallsdato',
    nn: 'Forfallsdato',
  },
  finalMessage: {
    en:'After the due date, penalty interest accrues on unpaid invoices, even if the payment deadline has been extended.',
    nb:'Etter forfallsdato påløper forsinkelsesrenter på ubetalte fakturaer, selv om betalingsfristen er forlenget.',
    nn:'Etter forfallsdato kjem forseinkingsrenter på ubetalte fakturaer, sjølv om betalingsfristen er forlengt.'
  }


};
