import { apiDispatcher } from '../utils';
import { FormApplicationTypeEnum, SubmittedFormDto } from '../models/digibank-mypage.models';

const baseUrl = '/api/v1/forms';

export const createForm = (applicationType: FormApplicationTypeEnum, formContext: any) => {
  return apiDispatcher<string>(`${baseUrl}/${applicationType}`, "POST", formContext, {
    resolver: (resp) => resp.text()
  });
}

export const uploadFile = async (formId: string, fieldName: string, file: any) => {
  const formData = new FormData();

  formData.append('file', file.file);
  formData.append('fieldName', fieldName);

  const upload = await fetch(`${baseUrl}/${formId}/UploadFile`, {
    method: 'POST',
    body: formData
  });

  if (upload.status === 422) {
    const validations = await upload.json() as Array<string>;

    return {
      status: false,
      message: validations.join(', ')
    }
  }

  const uploadedFile = await upload.json();
  
  return {
    status: true,
    newId: uploadedFile.uploadName
  }
}

export const deleteFile = async (formId: string, fileId: string) => {
  const result = {
    deleted: false,
    statusCode: 0
  };
  
  const deleteResult = await fetch(`${baseUrl}/${formId}/DeleteFile/${fileId}`, {
    method: 'DELETE'
  });

  result.deleted = true;
  result.statusCode = deleteResult.status;

  return result;
}

export const downloadFile = async (formId: string, fileId: string) => {
  const download = await fetch(`${baseUrl}/${formId}/GetFile/${fileId}`);

  return await download.blob();
}

export const downloadAsset = async (asset: any) => {
  const download = await fetch(`${baseUrl}/DownloadAsset`, { 
    body: JSON.stringify(asset),
    method: 'POST',
    credentials: "same-origin",
    headers: {
      "Content-Type": "application/json"
    }
  });

  if (download.status !== 200)
    return;

  return await download.blob();
}

export const getSubmittedForm = (formId: string) => {
  return apiDispatcher<SubmittedFormDto>(`${baseUrl}/${formId}/GetSubmitted`, "GET");
}
