import { LocalizedString } from '../../utils/localizer';

export const financingFormSelection: LocalizedString = {
  GeneralApplicationAdditionalInformation: {
    en: 'Tilleggsopplysninger til søknad',
    nb: 'Tilleggsopplysninger til søknad',
    nn: 'Tilleggsopplysninger til søknad',
  },
  GeneralApplicationAdditionalInformationPayoutRequest: {
    en: 'Tilleggsopplysninger til utbetalingsanmodning',
    nb: 'Tilleggsopplysninger til utbetalingsanmodning',
    nn: 'Tilleggsopplysningar til utbetalingsanmodning',
  },
  item2: {
    en: 'Respons to offer',
    nb: 'Svar på tilbud',
    nn: 'Svar på tilbud',
  },
  item3: {
    en: 'Acceptance of amendments',
    nb: 'Aksept av endringsforslag',
    nn: 'Aksept av endringsforslag',
  },
  item4: {
    en: 'Forwarding of documents',
    nb: 'Ettersending av dokumenter',
    nn: 'Ettersending av dokumenter',
  },
  GeneralApplicationPayoutRequest: {
    en: 'Utbetalingsanmodning',
    nb: 'Utbetalingsanmodning',
    nn: 'Utbetalingsanmodning',
  },
  GeneralApplicationResponseLetter: {
    en: 'Svar på vedtak',
    nb: 'Svar på vedtak',
    nn: 'Svar på vedtak',
  },
  item6: {
    en: 'Forwarding of payment documents',
    nb: 'Ettersending av utbetalingsdokumenter',
    nn: 'Ettersending av utbetalingsdokumenter',
  },
  item7: {
    en: 'Final report',
    nb: 'Sluttrapport',
    nn: 'Sluttrapport',
  },
  item8: {
    en: 'Confirmation of completed event',
    nb: 'Bekreftelse på gjennomført arrangement',
    nn: 'Stadfesting på gjennomført arrangement',
  },
  selectFormHeaderWithRole: {
    en: 'Send documents',
    nb: 'Send inn dokumenter',
    nn: 'Send inn dokumenter',
  },
  sendIn: {
    en: 'Submit:',
    nb: 'Send inn:',
    nn: 'Send inn:',
  },
  cancel: {
    en: 'Cancel',
    nb: 'Avbryt',
    nn: 'Avbryt',
  },
  submit: {
    en: 'Next',
    nb: 'Neste',
    nn: 'Neste',
  },
  chooseCaseNumberAndAccountNumber: {
    en: 'Choose a case to continue',
    nb: 'Velg ønsket saksnummer og kontonummer å gå videre med',
    nn: 'Velg ønsket saksnummer og kontonummer å gå videre med',
  },
  chooseCaseNumber: {
    en: 'Choose a case to continue',
    nb: 'Velg ønsket saksnummer å gå videre med',
    nn: 'Velg ønsket saksnummer å gå videre med',
  },
  chooseOneForm: {
    en: 'Choose one form',
    nb: 'Velg ett skjema',
    nn: 'Velg eit skjema',
  },
  yes: {
    en: 'yes',
    nb: 'ja',
    nn: 'ja',
  },
  no: {
    en: 'no',
    nb: 'nei',
    nn: 'nei',
  },
  allreadyExistingPayoutMessage: {
    en: 'Det finnes allerede en påbegynt utbetalingsanmodning. Denne blir slettet om du velger å fortsette. Ønsker du å fortsette?',
    nb: 'Det finnes allerede en påbegynt utbetalingsanmodning. Denne blir slettet om du velger å fortsette. Ønsker du å fortsette?',
    nn: 'Det finnes allerede en påbegynt utbetalingsanmodning. Denne blir slettet om du velger å fortsette. Ønsker du å fortsette?',
  }
};
