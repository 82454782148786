import React, { FC, useContext, useEffect, useState } from 'react';
import { Body, Section, LayoutGrid } from '@in/component-library';
import { Header } from './Header';
import { Footer } from './Footer';
import { NavMenu } from './NavMenu';
import { AppContext } from '../context/app-context';
import './Layout.scss';
import { LoadingSpinner } from './Shared/LoadingSpinner/LoadingSpinner';
import { getServiceMessages } from '../services/getServiceMessages.service';
import ServiceMessageList from './ServiceMessageList/ServiceMessageList';
import { ServiceMessage } from '../models/service-message';

export const Layout: FC<any> = (props: any) => {
  const { state } = useContext(AppContext);
  const [serviceMessages, setServiceMessages] = useState<Array<ServiceMessage>>([]);

  useEffect(() => {
    getServiceMessages()
      .then((serviceMessages) => setServiceMessages(serviceMessages))
      .catch((error) => console.log(error));

    return () => {
      setServiceMessages([]);
    };
  }, []);

  return (
    <AppContext.Consumer>
      {(appContext) => (
        <div className="layout-mainContent">
          <div className="layout-content">
            <Header />
            <Body>
              <Section>
                <LayoutGrid className="layoutGridMain">
                  <div className="app-content">
                    <Section collapse>{serviceMessages && serviceMessages.length > 0 && <ServiceMessageList messages={serviceMessages} />}</Section>
                    <NavMenu />
                    {state.application.loading ? <LoadingSpinner /> : props.children}
                  </div>
                </LayoutGrid>
              </Section>
            </Body>
          </div>
          <div className="layout-footer">
            <Footer />
          </div>
        </div>
      )}
    </AppContext.Consumer>
  );
};
