import React from 'react';
import { useLocalizer } from '../../hooks';
import { ApplicationDto } from '../../models';
import { Page } from '../Shared/Page/Page';
import './ApplicationCard.scss';

interface ApplicationCardProps {
  application: ApplicationDto
}

export const ApplicationCard: React.FC<ApplicationCardProps> = (props) => {
  const { l } = useLocalizer('ApplicationDetails');

  return (
    <Page
      title={props.application.projectName}
      subTitle={`${l('referenceNoLabel')} ${props.application.archiveCaseNo}`}
      goBackShowAllways
    />
  );
}