import { ActionMap } from '../types';

export type ApplicationState = {
  loading: boolean;
  showDirectDebitMessage: boolean;
};

export const initialApplicationState: ApplicationState = {
  loading: false,
  showDirectDebitMessage: true,
};

export enum ApplicationActionTypes {
  Loading = 'SET_LOADING',
  ShowDirectDebitMessage = 'SHOW_DIRECT_DEBIT_MESSAGE',
}

// App

type ApplicationPayload = {
  [ApplicationActionTypes.Loading]: boolean;
  [ApplicationActionTypes.ShowDirectDebitMessage]: boolean;
};

export type ApplicationActions = ActionMap<ApplicationPayload>[keyof ActionMap<ApplicationPayload>];

export const applicationReducer = (state: ApplicationState, action: ApplicationActions) => {
  switch (action.type) {
    case ApplicationActionTypes.Loading:
      return {
        ...state,
        loading: action.payload,
      };
    case ApplicationActionTypes.ShowDirectDebitMessage:
      return {
        ...state,
        showDirectDebitMessage: action.payload,
      };
    default:
      return state;
  }
};
