import { useLocation, useNavigate } from 'react-router-dom';
import { useLocalizer } from '../hooks';
import './NavMenu.scss';
import { Tabs, TabDefinition } from './Shared/Tabs/Tabs';

export const NavMenu = () => {
  const { l, globalL } = useLocalizer('Navigation');

  const location = useLocation();
  const navigate = useNavigate();

  const mainNavMenu = ['/bankaccounts', '/casemanagement', '/documents'];

  const showNavMenu = mainNavMenu.find((l) => l === location.pathname) !== undefined;
  const showNavMenuIndex = mainNavMenu.findIndex((l) => l === location.pathname);

  const tabs: TabDefinition[] = [
    {
      id: 0,
      label: l('bankAccounts'),
      title: l('bankAccounts'),
    },
    {
      id: 1,
      label: l('caseManagement'),
      title: l('caseManagement'),
    },
    {
      id: 2,
      label: l('documents'),
      title: l('documents'),
    },
  ];

  const handleTabOnSelect = (n: number): void => {
    navigate(mainNavMenu[n]);
  };

  return showNavMenu ? (
    <>
      <h1>{globalL('pageTitle')}</h1>
      <Tabs aria-label={l('menuAriaLabel')} tabs={tabs} handleTabOnSelect={(i) => handleTabOnSelect(i)} overrideSelection={showNavMenuIndex ?? 0}></Tabs>
    </>
  ) : (
    <></>
  );
};
