import { useState, useEffect } from 'react';
import { fromEvent } from 'rxjs';
import { map } from 'rxjs/operators';

const useResponsiveView = () => {
  const [width, setWidth] = useState<number>(window.innerWidth);

  useEffect(() => {
    const resizeEvent = fromEvent(window, 'resize');
    const windowWidth = resizeEvent.pipe(map((event) => (event.target as any).innerWidth));
    const subscription = windowWidth.subscribe((width) => {
      setWidth(width);
    });
    return () => {
        subscription.unsubscribe();
    };
  }, []);

  return { width };
};

export { useResponsiveView };
